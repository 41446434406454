<template>
  <div>
 
    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      text="Carregando Playlists"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>

    <hit-comprador-playlist-add
      :add-playlist-sidebar-ativo.sync="addPlaylistSidebarAtivo"
      @recarregarTabela="carregarLista()"
    />
    
    <b-button class="mb-2" variant="primary" @click="addPlaylistSidebarAtivo = true">
      <span class="text-nowrap">Criar Playlist</span>
    </b-button>
        
            <b-row>
              <b-col v-for="playlist in playlists" :key="playlist.id" cols="12" md="4" lg="3" class="justify-content-center">
                        
                  <b-card
                    class="ecommerce-card"
                    no-body
                  >
                    <div class="item-img text-center position-relative">

                      <b-link 
                        :to="{ name: 'hits-playlist-info', params: { id: playlist.id } }"
                      >
                        <v-lazy-image
                          fluid
                          class="card-img-top"
                          :src-placeholder="caminhoImagem(playlist.capaPlaylist)"
                        />
                      </b-link>
                    </div>

                    <!-- Product Details -->
                    <b-link
                      class="playlist-nome"
                      :to="{ name: 'hits-playlist-info', params: { id: playlist.id } }"
                    >
                    <b-card-body class="item-name">
                      <h6>
                          {{ playlist.nomePlaylist }}
                          
                        </h6>
                      </b-card-body>
                    </b-link>

                    <!-- Action Buttons -->
                    <div class="item-options text-center">
                      <b-button
                        variant="primary"
                        class="btn-cart move-cart"
                        style="border-radius: 0px 0px 8px 8px !important;width: 100%;"
                        :to="{ name: 'hits-playlist-info', params: { id: playlist.id } }"
                      >
                        <span>Reproduzir</span>
                        <b-icon
                          icon="music-note"
                          class="mr-50"
                        />
                      </b-button>
                    </div>
                  </b-card>
              </b-col>
            </b-row>
          
</div>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardText, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import useJwt from '@/auth/jwt/useJwt'
import VLazyImage from 'v-lazy-image/v2';

import HitCompradorPlaylistAdd from './HitCompradorPlaylistAdd.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueElementLoading,
    VLazyImage,
    HitCompradorPlaylistAdd,
  },
  data() {
    return {
      addPlaylistSidebarAtivo: false,
      playlists: [],
      perPage: 20,
      currentPage: 1,
      totalLista: 0,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,
      respostaApi: false,
      respostasApi: {},
    }
  },
  created() {
    this.usuarioCompradorPlaylist();
  },
  methods: {
    caminhoImagem(capaPlaylist) {
      if (capaPlaylist) {
        return capaPlaylist;
      } else {
        return require('@/assets/images/previas/campanha.png');
      }
    },

    usuarioCompradorPlaylist() {
      this.carregamentoApi = true
      const usuarioId = JSON.parse(localStorage.getItem('usuarioData'))?.sub
      const paginaAtual = this.currentPage - 1;
      useJwt.playlistListar({
        usuarioId:  usuarioId,
        page: paginaAtual,
        size: this.perPage,
        sort: "ASC"
      })
      .then(response => {
        this.playlists = response.data.content
        this.totalLista = response.data.totalElements
      })
      .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    mudarPagina() {
        this.carregarLista()
      },

      alterarPorPagina() {
        this.carregarLista()
      },

      removerPlaylist(playlistId) {
        this.$swal({
          title: 'Tem certeza?',
          text: 'Você está prestes a remover esta Playlist. Esta ação não pode ser revertida.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, remover',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$set(this.respostasApi, playlistId, true)
            useJwt.playlistRemover({
              playlistId: playlistId,
            })
            .then(response => {
              this.$swal({
                title: 'Playlist Removida!',
                text: 'A playlist foi removida.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(() => {
                window.location.reload()
              });
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message || 'Ocorreu um erro ao remover a playlist.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).finally(() => {
              this.$set(this.respostasApi, playlistId, false)
              })
            }
        })
    },
  },
}
</script>

<style lang="scss">

.item-name {
  flex: 1;
}

.item-name > h6 {
  margin-top: 2px;
  font-size: 14px;
}
</style>
