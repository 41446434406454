<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="addPlaylistSidebarAtivo"
    bg-variant="white"
    sidebar-class="sidebar-lg full-width-sidebar"
    shadow
    backdrop
    no-header
    right
    @hidden="onSidebarHide"
    @change="(val) => $emit('update:add-playlist-sidebar-ativo', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Criar Playlist
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{invalid}"
        ref="abilityForm"
      >
        <!-- Form -->
        <b-form
          ref="abilityForms"
          class="p-2"
          @submit.prevent="playlistCadastrar"
        >

        <b-form-group label="Nome da Playlist" label-for="nomePlaylist">
          <validation-provider #default="{ errors }" name="nomePlaylist" rules="required">
            <b-form-input id="nomePlaylist" v-model="nomePlaylist" :state="errors.length > 0 ? false:null"
              placeholder="Digite o nome da Playlist"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        
          <b-form-group label="Descrição da Playlist" label-for="descricaoPlaylist">
            <validation-provider #default="{ errors }" name="descricaoPlaylist">
              <b-form-textarea id="descricaoPlaylist" v-model="descricaoPlaylist" :state="errors.length > 0 ? false : null" 
                  placeholder="Digite a descrição da Playlist"
                  rows="5"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Capa da Playlist" label-for="capaPlaylist">
            <validation-provider #default="{ errors }" name="capaPlaylist" rules="required" ref="capaProvider">
              <b-form-file id="capaPlaylist" v-model="capaPlaylist" :state="Boolean(capaPlaylist)" accept="image/jpeg, image/png, image/jpg"
                :placeholder="nomeArquivo ? nomeArquivo : 'Faça o upload da imagem'" @change="envioCapa($event)"/>
              <small class="text-danger">{{ errors[0] }}</small>

              <div v-if="capaCortada" class="mt-2 d-flex justify-content-center" style="width: 100%;">
                <img :src="capaCortada" alt="Pré-visualização" style="max-width: 100%; max-height: 200px;">
              </div>
            </validation-provider>
          </b-form-group>

          <b-modal ref="cropModal" hide-footer title="Ajuste a imagem" no-close-on-backdrop no-close-on-esc>
            <div style="max-width: 100%; height: 400px; display: flex; justify-content: center; align-items: center;">
              <img ref="image" :src="imageUrl" alt="Capa da Playlist" style="width: 100%; max-height: 100%;" />
            </div>
            <div class="d-flex justify-content-end mt-3">
              <b-button variant="primary" @click="cropImage">Salvar</b-button>
            </div>
          </b-modal>
          
          
          <!-- Form Actions -->
          <div class="botoesAcao d-flex justify-content-between mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || respostaApi"
            >
              Adicionar
              <b-spinner
                v-if="respostaApi"
                small
                label="Carregando"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton, BSpinner,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { localize } from "vee-validate";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

localize({
  en: {
    messages: {
    },
    fields: {
      nomePlaylist: {
        required: 'O nome é obrigatório.',
      },
      capaPlaylist: {
        required: 'A capa é obrigatória.'
      }
    }
  }
});

export default {
  components: {
    BFormFile,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    Cropper,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'addPlaylistSidebarAtivo',
    event: 'update:add-playlist-sidebar-ativo',
  },
  props: {
    addPlaylistSidebarAtivo: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      nomePlaylist: '',
      descricaoPlaylist: '',
      capaPlaylist: '',
      usuarioId: null,
      respostaApi: false,
      cropper: null,
      imageUrl: '',
      capaCortada: '',
      nomeArquivo: '',
    }
  },
  methods: {  
    playlistCadastrar() {
      this.$refs.abilityForm.validate().then(success => {
          if (success) {
          this.respostaApi = true
          this.usuarioId = JSON.parse(localStorage.getItem('usuarioData'))?.sub
          
          const formData = new FormData()
          formData.append('usuarioId', this.usuarioId)
          formData.append('nome', this.nomePlaylist)
          formData.append('descricao', this.descricaoPlaylist)
          formData.append('capa', this.capaPlaylist)

          useJwt.playlistCadastrar(formData)
              .then(response => {
                this.$swal({
                    title: 'Playlist criada!',
                    text: 'A playlist foi criada com sucesso.',
                    icon: 'success',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,

              }).then(result => {
                  if (result.isConfirmed) {
                  this.$refs.abilityForms.reset()
                  this.$emit('update:add-playlist-sidebar-ativo', false)
                  this.$emit('recarregarTabela')
                  } else {
                  this.$refs.abilityForms.reset()
                  this.$emit('update:add-playlist-sidebar-ativo', false)
                  this.$emit('recarregarTabela')
                }
                this.$emit('playlist-criada');
                if (this.$route.path === '/playlists') {
                  this.$router.go()
                }
                this.resetFormulario();
              })
              })
              .catch(error => {
              this.$swal({
                  title: 'Erro!',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                  confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
              })
              })
              .finally(() => {
              this.respostaApi = false
              })
          }
      })
    },
    resetFormulario() {
      this.$refs.abilityForms.reset();
      this.capaPlaylist = ''; 
      this.capaCortada = ''; 
      this.nomeArquivo = ''; 
      if (this.$refs.capaProvider) {
        this.$refs.capaProvider.reset();
      }
      if (this.$refs.abilityForm) {
        this.$refs.abilityForm.reset();
      }
    },
    onSidebarHide() {
      this.resetFormulario();
      this.$emit('update:add-playlist-sidebar-ativo', false);
    },

    envioCapa(event) {
      const file = event.target.files[0];
      if (file) {
        this.nomeArquivo = file.name; 
        this.imageUrl = URL.createObjectURL(file);
        this.$refs.cropModal.show();

        event.target.value = null;
        
        this.$nextTick(() => {
          const imageElement = this.$refs.image;
          this.cropper = new Cropper(imageElement, {
            aspectRatio: 16 / 9,
            autoCropArea: 1,
            viewMode: 1,
            zoomable: true,
            scalable: true,
            dragMode: 'move', 
            cropBoxMovable: false,
            cropBoxResizable: false,
            minCropBoxWidth: 800,
            minCropBoxHeight: 450,
            zoomOnTouch: true,  
            zoomOnWheel: true,  
          });
        });
      }
    },
    cropImage() {
      const canvas = this.cropper.getCroppedCanvas({
        width: 800,
        height: 450,
      });

      canvas.toBlob((blob) => {
        // Converte o Blob em File com o nome desejado
        this.capaPlaylist = new File([blob], 'capaPlaylist.png', { type: blob.type });
        this.capaCortada = canvas.toDataURL();

        this.$refs.cropModal.hide();
      });
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.b-modal .modal-dialog {
  max-width: 850px;
  width: 100%;
}

.b-modal .modal-body {
  max-height: 500px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .botoesAcao {
    padding-bottom: 70px;
  }
} 
@media (max-width: 767.98px) {
  .full-width-sidebar {
    height: 100vh !important; 
    width: 100vw !important;
    position: fixed !important; 
    overflow-y: auto;
  }

  .botoesAcao {
    padding-bottom: 70px;
  }
}
</style>
